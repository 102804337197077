<!--
 * @Description: 顶部菜单
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-10-09 17:17:51
 * @LastEditTime: 2024-02-29 13:18:18
 -->
 <template>
  <div class="top-nav-menu-container" :style="navStyle">
    <div style="display: flex; width: 600px">
      <div style="width: 188px">
        <div class="bannerStyle" :style="bannerStyles"></div>
        <div class="logo" @click="backHome" style="z-index: 2;position: relative;">
          <img
            v-if="!getFileUrl(componyMeta.loginLogo)"
            src="@/assets/images/enlogo.png"
            alt=""
            :style="logoStyle"
            class="src"
          />
          <img v-else :src="getFileUrl(componyMeta.loginLogo)" alt="" />
        </div>
      </div>
      <div style="flex: 1; display: flex; align-items: center">
        <div style="width: 100%;display: flex;align-items: center">
          <!-- <el-select
              style="width: 100px"
              v-model="searchdata.searchId"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in glbSearchList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select> -->
            <el-popover placement="bottom-start" width="600" trigger="manual" v-model="showpopover"  @show="getSearchhistory">
             <div @click.stop>
              <div class="search-history">
                <div class="search-history-title">
                  <span class="title">搜索历史</span>
                  <img
                    src="@/assets/images/deletehistory.png"
                    class="searchImg histort-del"
                    id="history-del-search"
                    @click="clearHistory"
                  />
                </div>
                <div class="search-history-content" id="search-history-content-values">
                  <span class="history-valitem" @click="searchhistory(item)" v-for="item in showhistory" :key="item.id">{{
                    item
                  }}</span>
                </div>
              </div>
              <div style="padding-left:24px;">
                <el-tabs v-model="activeName" @tab-click="changeTab">
                  <el-tab-pane label="综合" name="1"></el-tab-pane>
                  <el-tab-pane label="功能" name="2"></el-tab-pane>
                  <el-tab-pane label="帮助中心" name="3"></el-tab-pane>
                </el-tabs>
              </div>
              <div class="search-group">
                <div v-if="searchValuesShowList.length>0">
                  <div class="search-detail" id="search-detail-value-list">
                    <div
                      v-for="item in searchValuesShowList"
                      :key="item.id"
                      class="search-detail-value-item"
                    >
                      <div class="search-field">
                        <div style="margin-right:10px;width:40px;">
                          <en-icon v-if="item?.menudata
                          ?.customIcon" :name="setChildrenIcon(item.menudata
                          .customIcon)" size="30"
                          :style="{color:item.menudata.customColor}"
                          :class= "{
                            'en-icon':true,
                          }"/>
                        </div>
                        <div>
                          <p class="search-field-value" @click="goto(item)" v-html="replacetit(item.name )"></p>
                          <p class="search-field-value-desc">
                            <span  >{{item.recordType==='2'?'功能':'帮助文档'}}：{{item?.classifyRefName || item.name }}</span>
                            <span class="shuxian" style="margin:0 5px;" v-if="item?.accessTime" >|</span>
                            <span class="search-field-value-desc-split" v-if="item.accessTime">你在{{ item.accessTime }} 打开过</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="search-more"
                    v-if="isshowmore"
                    id="search-more-value"
                    style="cursor: pointer"
                    @click="showMore"
                  >
                  <i  class=" el-icon-arrow-down searchicon" ></i>
                   展开更多
                  </div>
                  <div  class="search-more"
                  v-else
                  id="search-more-value"
                  style="cursor: pointer">
                    已经到底啦~
                  </div>
                </div>
                <div v-else class="nodata-empty">
                  暂无数据
                </div>
              </div>

              <div class="search-group" v-if="searchBusinessValuesShowList.length>0">
                <p class="group-name">功能</p>
                <div v-if="searchBusinessValuesShowList.length>0">
                  <div class="search-detail" id="search-detail-value-list">
                    <div
                      v-for="item in searchBusinessValuesShowList"
                      :key="item.id"
                      class="search-detail-value-item"
                    >
                      <div class="search-field">
                        <div style="margin-right:10px;width:40px;">
                          <en-icon v-if="item?.menudata
                          ?.customIcon" :name="setChildrenIcon(item.menudata
                          .customIcon)" size="30"
                          :style="{color:item.menudata.customColor}"
                          :class= "{
                            'en-icon':true,
                          }"/>
                        </div>
                        <div>
                          <p class="search-field-value" @click="goto(item)" v-html="replacetit(item.name )"></p>
                          <p class="search-field-value-desc">
                            <span  v-if="item?.classifyRefName">{{item.recordType==='2'?'功能':'帮助文档'}}：{{item?.classifyRefName}}</span>
                            <span class="shuxian" style="margin:0 5px;" v-if="item?.classifyRefName&&item.accessTime" >|</span>
                            <span class="search-field-value-desc-split" v-if="item.accessTime">你在{{ item.accessTime }} 打开过</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="search-more"
                    style="cursor: pointer"
                    @click="showMorebusiness('2')"
                  ><i  class="el-icon-search searchicon" ></i>在功能中搜索更多
                  </div>
                </div>

              </div>

              <div class="search-group"  v-if="searchHelpValuesShowList.length>0">
                <p class="group-name">帮助中心</p>
                <div v-if="searchHelpValuesShowList.length>0">
                  <div class="search-detail" id="search-detail-value-list">
                    <div
                      v-for="item in searchHelpValuesShowList"
                      :key="item.id"
                      class="search-detail-value-item"
                    >
                      <div class="search-field">
                        <div style="margin-right:10px;width:40px;">
                          <en-icon v-if="item?.menudata
                          ?.customIcon" :name="setChildrenIcon(item.menudata
                          .customIcon)" size="30"
                          :style="{color:item.menudata.customColor}"
                          :class= "{
                            'en-icon':true,
                          }"/>
                        </div>
                        <div>
                          <p class="search-field-value" @click="goto(item)" v-html="replacetit(item.name )"></p>
                          <p class="search-field-value-desc">
                            <span >{{item.recordType==='2'?'功能':'帮助文档'}}：{{item?.classifyRefName||item.name}}</span>
                            <span class="shuxian" style="margin:0 5px;" v-if="item?.accessTime" >|</span>
                            <span class="search-field-value-desc-split" v-if="item.accessTime">你在{{ item.accessTime }} 打开过</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="search-more"
                    style="cursor: pointer"
                    @click="showMorebusiness('3')"
                  ><i  class="el-icon-search searchicon" ></i>在文档中搜索更多
                  </div>
                </div>

              </div>
             </div>

              <div slot="reference" class="search-container" @click.stop>
                <el-input
              placeholder="请输入内容"
              v-model="searchdata.keywords"
              class="input-with-select"
              @keyup.native="searchKeyup"
              @focus="showpop"
            >
            </el-input>
              <en-button @click="search" type="primary" style="min-width:40px;"><i  class="el-icon-search searchicon" ></i></en-button>
              </div>
            </el-popover>
        </div>
      </div>
    </div>
    <div class="click-area">
      <!-- 全屏按钮 -->
      <span
        v-if="checkedSameScreen"
        class="back-version-btn"
        style="background: #4694df"
        @click="openFull"
        >演示</span
      >
      <!-- <span v-if="checkedSameScreen" id="qwe" style="margin-right: 30px;">
        <en-image-setting ref="import" name="tongping" :size="28" fill="#4fd2c2" style="" @click.native="openFull"> </en-image-setting>
      </span> -->
      <span :class="{ 'red-star': showNum > 0 }" title="公司圈">
        <!-- <img @click="goCompanyCircle" class="friendster" src="http://cdn.enfry.com/companycircle.png" alt="" /> -->
        <en-button
          class="msg"
          icon="icongongsiquan"
          :icon-color="topIconColor ? topIconColor : '#26C393'"
          type="text"
          icon-size="18"
          @click="goCompanyCircle"
        ></en-button>
      </span>
      <el-badge :value="unreadNum" :max="99" class="msg-wrap" :hidden="!unreadNum">
        <en-button
          title="沟通"
          class="msg"
          icon="icongoutong-shouye"
          :icon-color="topIconColor ? topIconColor : '#4694df'"
          type="text"
          icon-size="17"
          @click="handleClickChat"
        ></en-button>
      </el-badge>
      <el-badge :value="unreadMailNum" :max="99" class="mail" :hidden="!unreadMailNum">
        <en-button
          title="邮箱"
          icon="icongerenyouxiang"
          :icon-color="topIconColor ? topIconColor : '#4694df'"
          type="text"
          icon-size="18"
          @click="jumpToMail"
        ></en-button>
      </el-badge>
      <en-button
        title="主题"
        class="theme"
        icon="iconzhuti"
        :icon-color="topIconColor ? topIconColor : '#9367EB'"
        type="text"
        icon-size="18"
        @click="themeSettingVisible = true"
      ></en-button>
      <el-divider direction="vertical" class="divider-line" :style="lineStyles"></el-divider>
      <div @click="onRightClick" class="platform">
        <en-user-logo
          :image-url="userLogo"
          :user-name="storedUserInfo.name"
          size="30px"
          class="logo"
        ></en-user-logo>
        <span class="name" :style="{ color: topIconColor ? topIconColor : '#1E1E1E' }">{{
          storedUserInfo.name + " " + storedUserInfo.depatementName
        }}</span>
        <en-icon
          name="zhankai"
          :style="{ fill: topIconColor ? topIconColor : '#A9B5C6' }"
          class="point"
          size="10px"
        >
        </en-icon>
      </div>
    </div>
    <switch-role-model
      v-if="switchRoleStatus"
      @closeModelFn="switchRoleStatus = false"
      @change="change"
    />
    <theme-setting :visible="themeSettingVisible" @close="themeSettingVisible = false" />
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
 mapGetters, mapState, mapMutations, createNamespacedHelpers
} from "vuex";
import { companyCircleService } from "@/api";
import { enService } from "@/api/en/index";
import { enChart } from "@/api/businessChart";

import API from "@/api/businessModel/list";
import { mailBoxService } from "@/api/mailBox/mailList/index";

import SockService from "@/api/sock.js";
import { getFileUrl } from "@/tools/getFileUrl";
import { rgbaToHex } from "@/tools/util";
import GlbSearchService from "@/api/homeMenu/glbSearchService.js";
import { menuRouterData, menuClassifyTypeEnum, menuDataTypeEnum } from "@/api/homeMenu/handleMenu";
import SwitchRoleModel from "./SwitchRoleModel";
import ThemeSetting from "../theme-setting";

const { mapState: mapStateDateVariables, mapMutations: mapMutationsDateVariables } = createNamespacedHelpers("businessModel");
const { mapState: mapStateTheme } = createNamespacedHelpers("main");

const glbSearchService = new GlbSearchService();
export default {
  name: "TopNavMenu",
  components: { SwitchRoleModel, ThemeSetting },
  props: {},
  data() {
    return {
      showpopover: false,
      activeName: "1",
      sockService: null,
      switchRoleStatus: false,
      // 存储的用户信息, copy的vuex,不直接用vuex是因为退出登录的时候vuex会删除,导致页面变成undefined
      storedUserInfo: {},
      unreadMailNum: 0,
      checkedSameScreen: false,
      // SockService 第一次成功链接 后在进行跳转
      firstFull: true,
      themeSettingVisible: false,
      glbSearchList: [],
      glbhistory: [],
      searchdata: {
        // pageNo: 1,
        // pageSize: 10,
        keywords: "",
        searchId: "",
        1: 3,
        2: 3,
        3: 3
      },
      searchValues: {}, // 搜索结果
      searchValuesShowList: [],
      searchBusinessValuesShowList: [],
      searchHelpValuesShowList: [],
      isshowmore: false,
      showbusiness: false,
      showhelp: false
    };
  },
  created() {
    this.queryRbacGloableVariableList();
    this.queryMailTotalNum();
    // this.getSearchhistory();
  },
  mounted() {
    console.log(this.http, 32);
    // this.getglbSearchtypeList();
    this.queryUserConfig();
    this.storedUserInfo = cloneDeep(this.userInfo);
    this.$eventHub.$on("changeUser", () => {
      this.storedUserInfo = cloneDeep(this.userInfo);
    });
    this.initWebscok();

    window.onclick = () => {
      this.showpopover = false;
    };
  },
  destroyed() {
    this.$eventHub.$off("changeUser");
  },
  computed: {
    ...mapState(["componyMeta", "urlParam"]),
    ...mapStateTheme(["themeInfo"]),
    ...mapStateDateVariables(["dateVariables"]),
    ...mapGetters(["userInfo", "appSessionId"]),
    showhistory() {
      try {
        const list = JSON.parse(JSON.stringify(this.glbhistory || JSON.stringify([]))) || [];
        return list.reverse();
      } catch (e) {
        return [];
      }
    },
    userLogo() {
      const { userLogo } = this.storedUserInfo;
      const urlParam = this.urlParam || {};
      return userLogo?.indexOf("http") !== -1 || !urlParam.showImageServer
        ? userLogo
        : `${urlParam.showImageServer}?token=${urlParam.showImageToken}&ts=${urlParam.imgTs}&fileCode=${userLogo}&appKey=${urlParam.appKey}`;
    },
    // 导航栏
    bannerStyles() {
      let style = "";
      if (this.themeInfo.navStyle === 1) {
        // 布局一
        if (this.themeInfo.RecommendedStyles === 1) {
          // 顶部栏通屏
          style = `
            width:188px;
            `;
          console.log("style", style);
        } else {
          // 导航栏通屏
          // eslint-disable-next-line no-lonely-if
          if (this.themeInfo.isCollapse) {
            style = `
            width:80px;
            background-color:${this.themeInfo.navColor}
            `;
          } else {
            style = `
            width:188px;
            background-color:${this.themeInfo.navColor}
            `;
          }
        }
      } else if (this.themeInfo.navStyle === 2) {
        // 布局二
        if (this.themeInfo.RecommendedStyles === 3) {
          // 顶部栏通屏
          style = `
            width:123.2px;
            `;
        } else {
          // 导航栏通屏
          // eslint-disable-next-line no-lonely-if
          if (this.themeInfo.isCollapse) {
            style = `
            width:80px;
            `;
          } else {
            style = `
            width:123.2px;
            background-color:${this.themeInfo.navColor}
            `;
          }
        }
      }
      return style;
    },
    sessionlist() {
      return this.$store.state.chat.sessionlist;
    },
    showMsgNum() {
      return this.$store.state.chat.gsqMsgNum.msgCount;
    },
    showCountNum() {
      return this.$store.state.chat.gsqMsgNum.count;
    },
    showNum() {
      return this.showMsgNum + this.showCountNum;
    },
    unreadNum() {
      const totalUnRead = this.sessionlist
        .map((item) => item.unread)
        .reduce((pre, next) => pre + next, 0);
      return totalUnRead;
    },
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    topIconColor() {
      const { TopIcon, TopIconColor } = this.themeInfo;
      console.log("TopIconColor", TopIconColor);
      if (TopIcon === "2") {
        return rgbaToHex(TopIconColor);
      }
      return "";
    },
    lineStyles() {
      const { TopIcon, TopIconColor } = this.themeInfo;
      if (TopIcon === "2") {
        return { backgroundColor: rgbaToHex(TopIconColor) };
      }
      return { background: "rgba(96, 98, 102, 0.2)" };
    },
    navStyle() {
      const resStyle = {};
      const { openCustomColor, topColor, topSettingRadio } = this.themeInfo;
      // console.log(topSettingRadio, "topSettingRadio");
      if (openCustomColor) {
        // eslint-disable-next-line eqeqeq
        if (topSettingRadio == 1) {
          resStyle.background = topColor;
          // eslint-disable-next-line eqeqeq
        } else if (topSettingRadio == 2) {
          resStyle["background-image"] = "url('https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342')";
          // resStyle.background: "url('https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342')";
          resStyle["background-repeat"] = "no-repeat";
          // resStyle.background-repeat = '';
          // "cover";
          resStyle["background-size"] = "100% 100%";
        } else {
          resStyle.background = "unset";
        }
      } else if (this.themeInfo.navStyle === 3) {
        if (this.themeInfo.RecommendedStyles === 5) {
          resStyle.background = topColor;
          // resStyle.borderRadius = "0 0 20px 20px";
          // {
          //   background: topColor,
          //   borderRadius: "0 0 20px 20px"
          // };
        }
      } else {
        resStyle.background = topColor;
      }

      // console.log(resStyle, "resStyleresStyle");
      return resStyle;
    },
    textStyle() {
      const { topIconColor } = this.themeInfo;
      console.log(topIconColor, "topIconColor");
      if (topIconColor) {
        return {
          color: topIconColor
        };
      }
      return {
        color: "#4694df"
      };
    },
    logoStyle() {
      const { isCollapse, navStyle, RecommendedStyles } = this.themeInfo;
      // 样式一 长菜单
      if ((navStyle === 1 && RecommendedStyles === 1) || (navStyle === 1 && !isCollapse)) {
        return {
          marginLeft: "50px"
        };
      }
      if ((navStyle === 2 && RecommendedStyles === 3) || (navStyle === 2 && !isCollapse)) {
        return { marginLeft: "25px" };
      }
      if ((navStyle === 3 && RecommendedStyles === 5) || (navStyle === 3 && !isCollapse)) {
        return { marginLeft: "35px" };
      }
      return {};
    },
    menulist() {
      const menuList = this.$store.getters["main/menuList"];
      return menuList;
    }
  },
  watch: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // 监听路由变化 请求接口 更新部分数据
    $route: {
      handler() {
        this.queryUserConfig();
        this.queryMailTotalNum();
      }
    }
  },
  methods: {
    replacetit(str) {
      if (this.searchdata.keywords && str) {
        const reg = new RegExp(this.searchdata.keywords, "img");
       return str.replace(reg, `<span style="color:#3e90fe;">${this.searchdata.keywords}</span>`);
      }
      return "";
    },
    setChildrenIcon(icon) {
      if (icon.indexOf("bus-board-icon") > -1 || icon.indexOf("bus-ywmb-icon") > -1) {
        return `${icon}_1`;
      }
      return icon;
    },
   async  goto(sitem) {
      console.log(2323, sitem);

      if (sitem.recordType === "3") {
    this.$router.push({
              path: "/help",
              query: { id: sitem.id, type: sitem.type }
            });

        this.$emit("menuClick", sitem.menudata);
        return;
      }
      const item = sitem.menudata;
       // menuFixedTypeEnum.businessClassify
       const itemData = item.data || item;
      if (itemData?.type === 3 && item.dataList) {
        if (item.refData.hasBoard === "0") {
          const res = await enChart.queryUserConfig({ configKey: `picList-${item.id}` });
          if (!res) {
            this.$router.push({
              name: "boardList",
              query: { id: item.data.id }
            });

            // this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
            this.$emit("menuClick", itemData);
          } else if (res.value === "1") {
            // 进图表
            this.$router.push({ path: "/toModelChartUser", query: { templateId: item.id, type: 1 } });
            this.$emit("menuClick", itemData);
          } else {
            // 2 进列表
            this.$router.push({
              name: "boardList",
              query: { id: item.data.id }
            });
            this.$emit("menuClick", itemData);
          }
        } else {
          this.$router.push({
            name: "boardList",
            query: { id: item.id }
          });
          this.$emit("menuClick", itemData);
        }
      } else {
        // if (item.data.code === menuFixedTypeEnum.mySalarySheet) {
        //   this.payRollPath = menuRouterData(item.data);
        //   this.$refs.promptIndex.handlePayRoll();
        // } else {
        const { path, query } = menuRouterData(itemData || item);

        if (itemData.icon === "2_18" || itemData.code === "073") {
          // 法律知识库and邮箱
          const routeData = this.$router.resolve({
            path
          });
          window.open(routeData.href, "_blank");
        } else if (itemData.code === "075") {
          // 外部链接 1-在新窗口显示，2-在当前窗口显示
          let out = itemData.outUrl;
          out = out.replace("{token}", sessionStorage.getItem("jwtToken"));
          if (itemData.outType === 1) {
              window.open(out);
          } else {
              window.location.href = out;
          }
        } else if (path.length > 0) {
          this.$router.push({
            path,
            query
          });
          this.$emit("menuClick", itemData);
        } else if (item?.data?.dataType === 26) {
          this.$router.push({
            path: "/outersearch",
            query: {
              id: item.data.refId
            }
          });
        }
        // }
      }

      // const currentSearch = this.glbSearchList.find((v) => v.id === this.searchdata.searchId);
      // const pa = [
      //     {
      //       nameVariable: data.field,
      //       fieldType: 1,
      //       fieldName: data.fieldName,
      //       areaObjType: "",
      //       areaObjTypeId: "",
      //       parentField: "",
      //       parentFieldType: "",
      //       valueType: "2",
      //       opType: "1",
      //       value: data.fieldValue,
      //       valueText: data.fieldValue,
      //       timeFormat: "",
      //       parentName: ""
      //     }
      //   ];

      // if (currentSearch.redirect === 1) {
      //   // this.$router.push({
      //   //   path: "/businessModel/detail",
      //   //   query: {
      //   //     id: data.dataId,
      //   //     templateId: data.templateId,
      //   //     openType: "2",
      //   //     pageType: "1",
      //   //     businessType: "2",
      //   //     viewType: this.$route.query.viewType
      //   //   }
      //   // });
      //   sessionStorage.setItem(data.templateId, JSON.stringify(pa));
      //   window.location.href = `/user/businessModel/list?templateId=${data.templateId}&templateName=${data.templateName}&type=2`;

      //   // this.$router.push({
      //   //   path: "/businessModel/list",
      //   //   query: {
      //   //     templateId: data.templateId,
      //   //     templateName: data.templateName,
      //   //     type: "2"
      //   //   }
      //   // });
      // }
      // if (currentSearch.redirect === 2) {
      //   sessionStorage.setItem(data.templateId, JSON.stringify(pa));
      //   window.location.href = `/user/businessModel/list?templateId=${data.templateId}&templateName=${data.templateName}&type=2`;
      // }

      // if (currentSearch.redirect === 3) {
      //   window.location.href = `/user/businessModel/detail?templateId=${data.templateId}&id=${data.dataId}&openType=2&pageType=1&businessType=2&viewType=${this.$route.query.viewType}`;
      //   // this.$router.push({
      //   //   path: "/businessModel/detail",
      //   //   query: {
      //   //     // id: data.dataId,
      //   //     // templateId: data.templateId,
      //   //     openType: "2",
      //   //     pageType: "1",
      //   //     businessType: "2",
      //   //     viewType: this.$route.query.viewType
      //   //   }
      //   // });
      // }
    },
    async clearHistory() {
      // const res = await glbSearchService.delSearchHistory({ type: "2" });
      this.glbhistory.splice(0);
      localStorage.setItem("globalSearchHistory", JSON.stringify([]));
    },
    getValues(list) {
      list.forEach((v) => {
        if (v.fieldList && v.fieldList.length > 0) {
          v.fieldList.forEach((m) => {
            if (!this.searchValues[`${m.fieldName}${m.fieldValue}`]) {
              this.searchValues[`${m.fieldName}${m.fieldValue}`] = {
                fieldName: m.fieldName,
                fieldValue: m.fieldValue,
                templateList: []
              };
            }
            const hasself = this.searchValues[
              `${m.fieldName}${m.fieldValue}`
            ].templateList.findIndex((n) => n.templateId === v.templateId);
            if (hasself === -1) {
              this.searchValues[`${m.fieldName}${m.fieldValue}`].templateList.push({ ...v, ...m });
            }
          });
        }
      });
      Object.keys(this.searchValues).forEach((v) => {
        this.searchValuesShowList.push(this.searchValues[v]);
      });
    },
    search() {
      this.showpop();
      const globalSearchHistory = localStorage.getItem("globalSearchHistory");
      try {
      this.glbhistory = JSON.parse(globalSearchHistory);

      if (!this.glbhistory.includes(this.searchdata.keywords)) {
        this.glbhistory.push(this.searchdata.keywords);
        if (this.glbhistory.length > 10) {
          this.glbhistory = this.glbhistory.splice(1);
        }
        localStorage.setItem("globalSearchHistory", JSON.stringify(this.glbhistory));
      }
    } catch (e) {
      this.glbhistory = [];
      this.glbhistory.push(this.searchdata.keywords);
        localStorage.setItem("globalSearchHistory", JSON.stringify(this.glbhistory));
    }
      this.searchdata.pageNo = 1;
      this.searchValuesShowList.splice(0);
      this.searchValues = {};
      this.searchdata[1] = 3;
      this.searchdata[2] = 3;
      this.searchdata[3] = 3;
      this.globalSearch("1"); this.globalSearch("2"); this.globalSearch("3");
      this.getSearchhistory();
    },
    showpop() {
      this.showpopover = true;
    },
    searchKeyup(evt) {
      console.log(evt.keyCode);
      if (evt.keyCode === 13) {
        this.search();
      }
    },

    searchhistory(val) {
      this.searchdata.keywords = val;
      this.search();
    },
    showMorebusiness(type) {
      this.activeName = type;
      this.searchValuesShowList = [];
      this.globalSearch();
    },
    showMore() {
      // if (this.searchdata.pageSize === 3) {
      //   this.searchdata.pageNo = 1;
      // } else {
      //   this.searchdata.pageNo++;
      // }
      // this.searchdata.pageSize = 10;
      this.searchValuesShowList = [];
      this.globalSearch();
    },
    changeTab() {
      // this.searchdata.pageSize = 10;
      // this.searchdata.pageNo = 1;
      this.searchValuesShowList = [];
      this.globalSearch();
    },
    // async getglbSearchtypeList() {
    //   const res = await glbSearchService.queryGlobalSearchList();
    //   this.glbSearchList = res;
    // },
    async getSearchhistory() {
    const globalSearchHistory = localStorage.getItem("globalSearchHistory");
    try {
      this.glbhistory = JSON.parse(globalSearchHistory);
    } catch (e) {
      this.glbhistory = [];
    }
      // this.glbhistory.splice(0);
      // const res = await glbSearchService.queryGlobalSearchHistory();
      // res.forEach((v) => {
      //   this.glbhistory.push(v);
      // });
    },

    async globalSearch(type) {
      if (!this.searchdata.keywords) return;
      const res = await glbSearchService.globalSearchv2({
        keywords: this.searchdata.keywords,
        searchType: type || this.activeName,
        pageSize: this.searchdata[type || 1]

      });

      this.searchdata[type] = res.total;

      // this.searchValuesShowList = res.records;

      // console.log("eqweqe", this.menulist);
      // const ids = ["1853676725701046273", "1856953442754105345", "450839947351425024", "442393085402415104"];

      const reslist = res.records;

      const arr = [];
      const list = JSON.parse(JSON.stringify(this.menulist));

      reslist.forEach((resv) => {
        if (resv.recordType === "2") {
          list.forEach((v) => {
            if (v.id === resv.id || v.refId === resv.id) {
              arr.push({ menudata: v, ...resv });
            }
            if (v.dataList && v.dataList.length > 0) {
              v.dataList.forEach((m) => {
                if (m.id === resv.id || m.refId === resv.id) {
                  m.parent = v;
                  arr.push({ menudata: m, ...resv });
                }
              });
            }
          });
        } else {
         const hedata = list.find((x) => x.customType === 8);
         arr.push({ menudata: hedata, ...resv });
            }
      });

      console.log(arr);
      // this.searchValuesShowList = arr;

      if (type === "1") {
        this.searchValuesShowList = arr;
        if (res.records.length < res.total) {
          this.isshowmore = true;
        } else {
          this.isshowmore = false;
        }
      } else if (type === "2") {
        this.searchBusinessValuesShowList = arr;
      } else if (type === "3") {
        this.searchHelpValuesShowList = arr;
      } else {
        this.searchValuesShowList.push(...arr);
        this.searchBusinessValuesShowList = [];
        this.searchHelpValuesShowList = [];
        if (res.records.length < res.total) {
          this.isshowmore = true;
        } else {
          this.isshowmore = false;
        }
      }

      // list.forEach((v) => {
      //   if (v.recordType === 2) {
      //     const d = reslist.find((m) => m.id === v.id);
      //   if (d) {
      //     arr.push({ searchdata: d, ...v });
      //   }
      //   if (v.dataList && v.dataList.length > 0) {
      //     v.dataList.forEach((m) => {
      //       const d = reslist.find((n) => n.id === v.id);
      //       if (d) {
      //         m.parent = v;
      //         arr.push({ searchdata: d, ...v });
      //       }
      //     });
      //   }
      //   } else {
      //     arr.push(v);
      //   }
      // });

      // if (this.searchdata.searchId) {
      //   const res = await glbSearchService.globalSearch(this.searchdata);
      //   this.getValues(res.records);
      //   if (res.hasNext) {
      //     this.isshowmore = true;
      //   } else {
      //     this.isshowmore = false;
      //   }
      // }
    },

    getFileUrl(url) {
      return getFileUrl(url, "000", "");
    },
    ...mapMutations("chat", ["updateGsqMsgInfo"]),
    ...mapMutationsDateVariables(["saveDateVariables"]),

    backVersion() {
      const { appSessionId } = this;
      // const urlMap = {
      //   "192.168.1.52": `https://en.enfry.com/redirectLogin.do?appSessionId=${appSessionId}`,
      //   "en1.enfry.com": `https://en.enfry.com/redirectLogin.do?appSessionId=${appSessionId}`,
      //   "192.168.1.52:8024": `https://en.enfry.cn/redirectLogin.do?appSessionId=${appSessionId}`,
      //   "192.168.1.52:8074": `https://zhun.enfry.cn/redirectLogin.do?appSessionId=${appSessionId}`,
      //   "192.168.1.52:8023": `https://dev.enfry.cn/redirectLogin.do?appSessionId=${appSessionId}`
      // };
      window.location.href = `https://master.softheluo.com/index.do?appSessionId=${appSessionId}`;
    },
    async queryMailTotalNum() {
      const rsp = await mailBoxService.countMail({
        mailAccount: "",
        type: 1,
        statuses: 1,
        readFlag: 1
      });
      this.unreadMailNum = rsp;
    },
    async queryRbacGloableVariableList() {
      if (!this.dateVariables.length) {
        const variableList = await API.queryRbacGloableVariableList({ type: 3 });
        this.saveDateVariables(variableList);
      }
    },
    initWebscok() {
      this.sockService = new SockService(
        `wss://${this.userInfo.enfryIp}/news/socketServer?channelType=PC&mobileNo=${this.userInfo.mobileNo}`,
        this.getNewNumber
      );
    },
    /**
     * @description: 获取新消息数量和待回执数量
     */
    async getNewNumber(evt) {
      this.firstFull = false;
      const This = this;
      const data = JSON.parse(evt.data);
      // console.log(data);
      if (["000", "001", "002"].includes(data.type)) {
        const rsp = await companyCircleService.readReceipt({});
        // const showNumRes = (rsp.msgCount || 0) + (rsp.count || 0);
        // console.log(showNumRes);
        rsp.msgCount = rsp.msgCount || 1; // TODO 临时方案，解决公司圈消息对当前用户可见后 接口msgCount不更新的问题
        this.updateGsqMsgInfo(rsp);
      }
      // 接受同屏信息 调用全屏
      if (data.path) {
        // if (data.tenantId === this.storedUserInfo.tenantId && data.userId !== this.storedUserInfo.userId) {
        if (data.path === "/indexVice") {
          // 用模拟点击的方式进入副页
          // 标记为副页
          window.localStorage.setItem("sameScreenAuthType", "indexVice");
          // this.fireKeyEvent("keydown", 122);
          This.$router.push({ path: data.path });
        } else if (data.path === "-1") {
          if (window.localStorage.getItem("sameScreenAuthType") === "indexVice") {
            this.$router.push({ path: "/indexVice" });
          } else {
            this.$router.push({ path: "/indexFull" });
          }
        } else if (data.path === "2") {
          // 特殊处理
          if (window.location.hostname === "en2.enfry.com") This.$router.push({ path: data.path2 });
          if (window.location.hostname === "en3.enfry.com") This.$router.push({ path: data.path3 });
        } else {
          This.$router.push({ path: data.path });
        }
        // }
      }
    },
    fireKeyEvent(evtType, keyCode) {
      const doc = document;
      const win = doc.defaultView || doc.parentWindow;
      let evtObj;
      if (doc.createEvent) {
        if (win.KeyEvent) {
          evtObj = doc.createEvent("KeyEvents");
          evtObj.initKeyEvent(evtType, true, true, win, false, false, false, false, keyCode, 0);
        } else {
          evtObj = doc.createEvent("UIEvents");
          Object.defineProperty(evtObj, "keyCode", {
            get() {
              return this.keyCodeVal;
            }
          });
          Object.defineProperty(evtObj, "which", {
            get() {
              return this.keyCodeVal;
            }
          });
          evtObj.initUIEvent(evtType, true, true, win, 1);
          evtObj.keyCodeVal = keyCode;
          if (evtObj.keyCode !== keyCode) {
            console.log(`keyCode${evtObj.keyCode}和(${evtObj.which})不匹配`);
          }
        }
        document.dispatchEvent(evtObj);
      } else if (doc.createEventObject) {
        evtObj = doc.createEventObject();
        evtObj.keyCode = keyCode;
        document.fireEvent(`on${evtType}`, evtObj);
      }
    },
    // 进入公司圈
    goCompanyCircle() {
      this.$router.push({ path: "/company-circle" });
    },
    /**
     * 增加右边点击事件
     */
    onRightClick() {
      this.switchRoleStatus = true;
      this.$emit("right-click");
    },
    backHome() {
      this.$router.push({ path: "/home" });
    },
    change(val) {
      sessionStorage.setItem("tableTabs", []);
      this.$store.commit("changeOrg");
      this.storedUserInfo = cloneDeep(val);
      this.queryUserConfig();
      this.queryMailTotalNum();
    },
    handleClickChat() {
      this.$router.push({ path: "/chat" });
    },
    jumpToMail() {
      window.open("/user/mailbox/list", "_black");
    },
    /**
     * @description: 打开全屏
     * @param {type}
     * @return: isFullScreen = true;
     */
    openFull() {
      // this.$emit("openFull");
      const p1 = {
        tenantId: this.storedUserInfo.tenantId,
        userId: this.storedUserInfo.userId,
        path: "/indexVice"
      };
      const p = {
        type: "sameScreenAuth",
        value: JSON.stringify(p1)
      };
      // 标记为主屏
      window.localStorage.setItem("sameScreenAuthType", "indexFull");
      enChart.sendWebSocketMessage(p);
      this.$router.push({ path: "/indexFull" });
    },
    // 查询同屏授权
    async queryUserConfig(flg) {
      const p = { configKey: "sameScreenAuth" };
      const data = (await enService.queryUserConfig(p)) || {};
      // console.log(data);
      if (data.value === "0") {
        // 未读消息
        this.checkedSameScreen = true;
      } else if (data.value === "1") {
        this.checkedSameScreen = false;
      } else {
        // 默认
        this.checkedSameScreen = false;
      }
      // debugger;
      if (!flg) {
        const This = this;
        const t = setInterval(() => {
          // 建立了 websock 联系 再进行 自动跳转
          if (!This.firstFull) {
            clearInterval(t);
            // console.log(This.userInfo, 11111111111);
            const mobileNoLi = ["15000000013", "15000000011", "15000000012"];
            // window.localStorage.setItem("autoShow", "1");
            if (
              This.checkedSameScreen
              && window.location.pathname === "/user/home/index"
              && (window.localStorage.getItem("autoShow") === "1"
                || mobileNoLi.indexOf(This.userInfo.mobileNo) > -1)
            ) {
              window.localStorage.setItem("autoShow", "2");
              if (
                window.location.hostname === "en2.enfry.com"
                || window.location.hostname === "en3.enfry.com"
              ) {
                // 副屏
                This.$router.push({ path: "/indexVice" });
                window.localStorage.setItem("sameScreenAuthType", "indexVice");
              } else {
                // 主屏
                This.$router.push({ path: "/indexFull" });
                window.localStorage.setItem("sameScreenAuthType", "indexFull");
              }
            } else {
              window.localStorage.setItem("autoShow", "2");
            }
          }
        }, 100);
      }
    }
  }
};

</script>
<style scoped lang="scss">
.top-nav-menu-container {
  display: flex;
  // border-bottom: 1px solid #323e52;
  height: 61px;
  background: rgba(35, 44, 61, 1);
  justify-content: space-between;
  align-items: center;
  position: relative;
  .bannerStyle {
    position: absolute;
    left: 0;
    top: 0;
    height: 64px;
    // 顶部导航栏60 还有4px空隙
  }
  .src {
    height: 39px;
  }
  .click-area {
    .back-version-btn {
      display: inline-block;
      width: 102px;
      height: 32px;
      margin-right: 20px;
      text-align: center;
      border-radius: 16px;
      line-height: 32px;
      font-size: 12px;
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
      cursor: pointer;
    }
    .test-entry {
      margin-right: 20px;
      background-color: red;
      cursor: pointer;
    }
    margin-right: 20px;
    display: flex;
    align-items: center;
    .divider-line {
      margin: 0;
      height: 1.5em;
      background: rgba(96, 98, 102, 0.88);
      opacity: 0.2;
    }
    .platform {
      margin-left: 20px;
      color: #fff;
      cursor: pointer;
      .name {
        font-size: 12px;
        color: white;
        vertical-align: middle;
        margin-left: 6px;
      }
      .point {
        fill: white;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
    .friendster {
      height: 18px;
      cursor: pointer;
      line-height: 22px;
    }
    .red-star {
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #f56c6c;
        position: absolute;
        right: -2px;
        border-radius: 8px;
        top: 2px;
      }
    }
    .msg-wrap {
      margin-left: 20px;
      margin-right: 20px;
    }
    .mail {
      margin-right: 20px;
      margin-left: 0;
    }
    .theme {
      margin-right: 20px;
    }
    .msg {
      height: 18px;
      color: #4694df;
    }
    .click-menu {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
<style lang="scss" scoped>
.white-theme,
.blue-theme,
.green-theme,
.yellow-theme,
.purple-theme {
  .top-nav-menu-container {
    background: #fff;
    border-color: #fff;
    .click-area {
      .back-version-btn {
        box-sizing: border-box;
        border: 1px solid #4694df;
        color: #4694df;
      }
      .platform {
        .name {
          color: #1e1e1e;
        }
        .point {
          fill: #a9b5c6;
        }
      }
    }
  }
}
.logo {
  vertical-align: middle;
  img {
    max-height: 64px;
    max-width: 190px;
  }
}

.navbar-header {
  display: flex;
  align-items: center;
}
.glb-search-user {
  display: flex;
  align-items: center;
  width: unset;
  position: relative;
  margin-top: 20px;
}
.glb-search-user-input {
  width: 230px;
  border-right: none;
  border-left: none;
  float: left;
}

.search-content {
  position: absolute;
  min-width: 330px;
  min-height: 120px;
  top: 35px;
  left: 0px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 5px;
}

.search-history {
  font-size:14px;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e6e7;
}

.search-history-title {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

.search-history-title .title {
  font-weight: 500;
  color: #000;
  line-height: 27px;
}

.search-history-content {
  text-wrap: nowrap;
  padding: 3px 10px;
  font-size: 12px;
  overflow: auto;
}
.search-history-content span {
  margin: 0 5px;
}

.search-history-content span:hover {
  color: #49bef2;
  cursor: pointer;
}

.histort-del {
  cursor: pointer;
}

.search-detail {
  font-size: 12px;
}

.search-field {
  padding: 5px;
  border-bottom: 1px solid #e5e6e7;
  display:flex;
  align-items: center;
}

.search-field-value {
  margin: 0 5px;
  text-wrap: nowrap;
  font-weight:500;
  color:#000;
  cursor: pointer;
}
.search-field-value-desc{
  color: #989896;
  margin:5px 0 ;
}
.search-field-value-desc-split{

}
.search-model-value {
  padding: 5px;
  border-bottom: 1px solid #e5e6e7;
  padding-left: 20px;
}

.search-detail-value-item:last-child .search-model-value {
  border-bottom: none;
}

.searchImg {
  height: 20px;
  float: right;
  margin-top: 7px;
  cursor: pointer;
}

.glb-search-user-input-container {
  height: 32px;
  width: 260px;
}

#search-detail-value-list {
  max-height: 300px;
  overflow: auto;
  font-size:14px;
}

.search-more {
  text-align: center;
  display: flex;;
  margin: 10px 0px;
  justify-content: center;
}
.history-valitem {
  cursor: pointer;
}
.history-valitem:hover {
  color: #49bef2;
}

.searchicon {
  font-size: 20px;
}
.input-with-select{
  width:300px;
}

.search-container{
  display: flex;
  align-items: center;

}

.nodata-empty{
  text-align:center;
  line-height:50px;
}

.search-group{
  border-bottom:1px solid rgba(242, 242, 242, 1);
}
.group-name{
  font-size:16px;
}
</style>
